'use strict';

angular.module('webPartnerPortalApp', [
  'webPartnerPortalApp.constants',
  'ngCookies',
  'ngResource',
  'ngSanitize',
  'ngRoute',
  'mm.foundation',
  'angular-float-labels',
  'base64',
  'ngFileUpload'
])
.config(['$routeProvider', '$locationProvider',  function($routeProvider, $locationProvider) {
  $routeProvider
    .otherwise({
      redirectTo: '/partners'
    });
    $locationProvider.html5Mode(true);
}])
.controller('PartnerAppCtrl',['$rootScope','$scope', '$http','$location','EventDispatchingSvc', '$cookies', 'appConfig', '$base64', 
  function($rootScope, $scope, $http, $location, EventDispatchingSvc, $cookies, appConfig, $base64){
  var PartnerAppCtrlVM = this;
  angular.extend(PartnerAppCtrlVM, new EventDispatchingSvc($scope));
  PartnerAppCtrlVM.config = appConfig;
  PartnerAppCtrlVM.getData  = function(){
      return $cookies.get(PartnerAppCtrlVM.config.app_cookie);
  };


  $rootScope.$on('$routeChangeError',function(event, current, previous, rejection){
    try{
      if(rejection.status === 400){
        $location.url('/login');
      }
    }catch(e){}
  });

  PartnerAppCtrlVM.getInformation = function(){
      var base64Date = PartnerAppCtrlVM.getData();
      var decodedData = (!_.isEmpty(base64Date))?$base64.decode(base64Date):'';
      var info = {};
      if(!_.isEmpty(decodedData)){
        var agentObject = decodedData.split(',');
        info.id  = agentObject[0];
        info.email = agentObject[1];
        info.firstName = agentObject[2];
        info.lastName  = agentObject[3];
        info.roles   = agentObject[4].split('|');
        return info;
      }
    };

    Array.prototype.contains = function(k) {
      var p;
      for(p in this){
         if(this[p] === k){
            return true;
         }
      }
      return false;
    };


  PartnerAppCtrlVM.init = function(){
    var notToCall = ['/logout'];
    var currentURL = ($location.path()).toLowerCase();
    if(notToCall.contains(currentURL)){return;}
    $http({
      method: 'GET',
      url: '/api/login/checks'
    }).then(function successCallback(response) {
        PartnerAppCtrlVM.dispatch('access:login', response.data);
        if($location.path() === '/login' || $location.path() === '/'){
          $location.url('/partners');
        }else{
          var appURL = ($location.path()).toLowerCase();
          $location.url(appURL);
        }
    }, function errorCallback() {
        $location.url('/login');
    });
  };
  PartnerAppCtrlVM.init();

}]);
